<template>
    <b-container>
    <b-row v-if="sent" class="justify-content-md-center mt-5">
      <b-col>
        <b-icon style="width: 30px; height: 30px;" class="text-success mb-2" icon="check-circle"></b-icon>
        <h5>Verification email sent to {{$route.query.email}}</h5>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import firebase from "firebase/app"
require("firebase/auth")

export default {
    data(){
        return{
            sent:false,
        }
    },
    created(){
        this.resendVerificationEmail()
    },
    methods:{
    resendVerificationEmail() {
      let user = firebase.auth().currentUser;
      var actionCodeSettings = {url: "https://research.mindthebridge.com/login?email=" + user.email}
      user.sendEmailVerification(actionCodeSettings).then(() => { 
        console.log("EMAIL SENT");
        this.sent = true
        // this.emailSentToaster();
      })
    },
    }
}
</script>